import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["menu"]

    connect() {
        this.isOpen = false;  // Track the state of the menu
    }

    toggle() {
        this.isOpen = !this.isOpen;

        if (this.isOpen) {
            this.menuTarget.classList.remove("hidden");
        } else {
            this.menuTarget.classList.add("hidden");
        }
    }

    close() {
        this.isOpen = false;
        this.menuTarget.classList.add("hidden");
    }

    disconnect() {
        this.isOpen = false;
        this.menuTarget.classList.add("hidden");
    }
}